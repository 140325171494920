import { Stack } from "@mui/material";
import { default as MUISlider } from "@mui/material/Slider";
import useIsDesktop from "desktop/useIsDesktop";
import { useField } from "formik";

import SliderDescription from "./SliderDescription";

const PensionSlider = () => {
	const [field] = useField("amount");

	const isDesktop = useIsDesktop();

	const marks = [
		{
			value: 500,
			label: "500 Kč",
		},
		{
			value: 1700,
			label: "1700 Kč",
		},
		{
			value: 5700,
			label: "5700 Kč",
		},
	];

	return (
		<>
			<Stack sx={{ px: 6 }}>
				<SliderDescription />
				<Stack sx={{ px: 4 }}>
					<MUISlider
						min={0}
						max={5700}
						step={50}
						marks={marks}
						sx={{
							"& .MuiSlider-mark": {
								width: 24,
								height: 24,
								marginLeft: "-13px",
								backgroundImage: "url('/mark.svg')",
								backgroundPosition: "42px 45px",
								color: "transparent",
							},
							"& .MuiSlider-markLabel": {
								fontWeight: 600,
							},
							"& .MuiSlider-rail": {
								backgroundColor: "#5B9853",
							},
							"& .MuiSlider-thumb": {
								width: 40,
								height: 28,
								border: 0,
								borderRadius: "40%",
								backgroundImage: "url('/slider.svg')",
							},
							"& .MuiSlider-track": {
								color:
									field.value <= 500
										? "#E64415"
										: field.value <= 1150
										? "#EA650D"
										: field.value <= 1700
										? "#96B439"
										: "#5B9853",
							},
						}}
						{...field}
					/>
				</Stack>
			</Stack>
		</>
	);
};

export default PensionSlider;
