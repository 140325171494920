/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NdpInsuredPerson } from './NdpInsuredPerson';
import {
    NdpInsuredPersonFromJSON,
    NdpInsuredPersonFromJSONTyped,
    NdpInsuredPersonToJSON,
} from './NdpInsuredPerson';
import type { Participant } from './Participant';
import {
    ParticipantFromJSON,
    ParticipantFromJSONTyped,
    ParticipantToJSON,
} from './Participant';

/**
 * 
 * @export
 * @interface GetLifeDetailV2Response
 */
export interface GetLifeDetailV2Response {
    /**
     * 
     * @type {Participant}
     * @memberof GetLifeDetailV2Response
     */
    owner?: Participant;
    /**
     * 
     * @type {Array<NdpInsuredPerson>}
     * @memberof GetLifeDetailV2Response
     */
    insuredPersons: Array<NdpInsuredPerson>;
    /**
     * 
     * @type {number}
     * @memberof GetLifeDetailV2Response
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetLifeDetailV2Response
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetLifeDetailV2Response
     */
    proposalNumber: string;
    /**
     * 
     * @type {string}
     * @memberof GetLifeDetailV2Response
     */
    contractNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof GetLifeDetailV2Response
     */
    dateSignature: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetLifeDetailV2Response
     */
    dateIssue?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetLifeDetailV2Response
     */
    dateStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetLifeDetailV2Response
     */
    dateEnd: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetLifeDetailV2Response
     */
    technicalStartDate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetLifeDetailV2Response
     */
    hasTaxAdvantage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetLifeDetailV2Response
     */
    doesEmployerContribute: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetLifeDetailV2Response
     */
    premiumClient: number;
    /**
     * 
     * @type {number}
     * @memberof GetLifeDetailV2Response
     */
    frequency: number;
    /**
     * 
     * @type {string}
     * @memberof GetLifeDetailV2Response
     */
    frequencyCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLifeDetailV2Response
     */
    statusCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLifeDetailV2Response
     */
    commonStatusCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLifeDetailV2Response
     */
    commonStatusDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLifeDetailV2Response
     */
    productClass?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLifeDetailV2Response
     */
    productDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLifeDetailV2Response
     */
    sourceSystem?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetLifeDetailV2Response
     */
    premiumTotal: number;
    /**
     * 
     * @type {number}
     * @memberof GetLifeDetailV2Response
     */
    premiumEmployer: number;
    /**
     * 
     * @type {number}
     * @memberof GetLifeDetailV2Response
     */
    debtClient: number;
    /**
     * 
     * @type {number}
     * @memberof GetLifeDetailV2Response
     */
    debtEmployer: number;
    /**
     * 
     * @type {number}
     * @memberof GetLifeDetailV2Response
     */
    overpaymentClient: number;
    /**
     * 
     * @type {number}
     * @memberof GetLifeDetailV2Response
     */
    overpaymentEmployer: number;
    /**
     * 
     * @type {number}
     * @memberof GetLifeDetailV2Response
     */
    surrenderValue: number;
    /**
     * 
     * @type {number}
     * @memberof GetLifeDetailV2Response
     */
    sumPremiumClient: number;
    /**
     * 
     * @type {number}
     * @memberof GetLifeDetailV2Response
     */
    sumPremiumEmployer: number;
}

/**
 * Check if a given object implements the GetLifeDetailV2Response interface.
 */
export function instanceOfGetLifeDetailV2Response(value: object): value is GetLifeDetailV2Response {
    if (!('insuredPersons' in value) || value['insuredPersons'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('proposalNumber' in value) || value['proposalNumber'] === undefined) return false;
    if (!('contractNumber' in value) || value['contractNumber'] === undefined) return false;
    if (!('dateSignature' in value) || value['dateSignature'] === undefined) return false;
    if (!('dateStart' in value) || value['dateStart'] === undefined) return false;
    if (!('dateEnd' in value) || value['dateEnd'] === undefined) return false;
    if (!('technicalStartDate' in value) || value['technicalStartDate'] === undefined) return false;
    if (!('hasTaxAdvantage' in value) || value['hasTaxAdvantage'] === undefined) return false;
    if (!('doesEmployerContribute' in value) || value['doesEmployerContribute'] === undefined) return false;
    if (!('premiumClient' in value) || value['premiumClient'] === undefined) return false;
    if (!('frequency' in value) || value['frequency'] === undefined) return false;
    if (!('premiumTotal' in value) || value['premiumTotal'] === undefined) return false;
    if (!('premiumEmployer' in value) || value['premiumEmployer'] === undefined) return false;
    if (!('debtClient' in value) || value['debtClient'] === undefined) return false;
    if (!('debtEmployer' in value) || value['debtEmployer'] === undefined) return false;
    if (!('overpaymentClient' in value) || value['overpaymentClient'] === undefined) return false;
    if (!('overpaymentEmployer' in value) || value['overpaymentEmployer'] === undefined) return false;
    if (!('surrenderValue' in value) || value['surrenderValue'] === undefined) return false;
    if (!('sumPremiumClient' in value) || value['sumPremiumClient'] === undefined) return false;
    if (!('sumPremiumEmployer' in value) || value['sumPremiumEmployer'] === undefined) return false;
    return true;
}

export function GetLifeDetailV2ResponseFromJSON(json: any): GetLifeDetailV2Response {
    return GetLifeDetailV2ResponseFromJSONTyped(json, false);
}

export function GetLifeDetailV2ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLifeDetailV2Response {
    if (json == null) {
        return json;
    }
    return {
        
        'owner': json['owner'] == null ? undefined : ParticipantFromJSON(json['owner']),
        'insuredPersons': ((json['insuredPersons'] as Array<any>).map(NdpInsuredPersonFromJSON)),
        'id': json['id'],
        'isActive': json['isActive'],
        'proposalNumber': json['proposalNumber'],
        'contractNumber': json['contractNumber'],
        'dateSignature': (new Date(json['dateSignature'])),
        'dateIssue': json['dateIssue'] == null ? undefined : (new Date(json['dateIssue'])),
        'dateStart': (new Date(json['dateStart'])),
        'dateEnd': (new Date(json['dateEnd'])),
        'technicalStartDate': (new Date(json['technicalStartDate'])),
        'hasTaxAdvantage': json['hasTaxAdvantage'],
        'doesEmployerContribute': json['doesEmployerContribute'],
        'premiumClient': json['premiumClient'],
        'frequency': json['frequency'],
        'frequencyCode': json['frequencyCode'] == null ? undefined : json['frequencyCode'],
        'statusCode': json['statusCode'] == null ? undefined : json['statusCode'],
        'commonStatusCode': json['commonStatusCode'] == null ? undefined : json['commonStatusCode'],
        'commonStatusDescription': json['commonStatusDescription'] == null ? undefined : json['commonStatusDescription'],
        'productClass': json['productClass'] == null ? undefined : json['productClass'],
        'productDescription': json['productDescription'] == null ? undefined : json['productDescription'],
        'sourceSystem': json['sourceSystem'] == null ? undefined : json['sourceSystem'],
        'premiumTotal': json['premiumTotal'],
        'premiumEmployer': json['premiumEmployer'],
        'debtClient': json['debtClient'],
        'debtEmployer': json['debtEmployer'],
        'overpaymentClient': json['overpaymentClient'],
        'overpaymentEmployer': json['overpaymentEmployer'],
        'surrenderValue': json['surrenderValue'],
        'sumPremiumClient': json['sumPremiumClient'],
        'sumPremiumEmployer': json['sumPremiumEmployer'],
    };
}

export function GetLifeDetailV2ResponseToJSON(value?: GetLifeDetailV2Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner': ParticipantToJSON(value['owner']),
        'insuredPersons': ((value['insuredPersons'] as Array<any>).map(NdpInsuredPersonToJSON)),
        'id': value['id'],
        'isActive': value['isActive'],
        'proposalNumber': value['proposalNumber'],
        'contractNumber': value['contractNumber'],
        'dateSignature': ((value['dateSignature']).toISOString().substring(0,10)),
        'dateIssue': value['dateIssue'] == null ? undefined : ((value['dateIssue'] as any).toISOString().substring(0,10)),
        'dateStart': ((value['dateStart']).toISOString().substring(0,10)),
        'dateEnd': ((value['dateEnd']).toISOString().substring(0,10)),
        'technicalStartDate': ((value['technicalStartDate']).toISOString().substring(0,10)),
        'hasTaxAdvantage': value['hasTaxAdvantage'],
        'doesEmployerContribute': value['doesEmployerContribute'],
        'premiumClient': value['premiumClient'],
        'frequency': value['frequency'],
        'frequencyCode': value['frequencyCode'],
        'statusCode': value['statusCode'],
        'commonStatusCode': value['commonStatusCode'],
        'commonStatusDescription': value['commonStatusDescription'],
        'productClass': value['productClass'],
        'productDescription': value['productDescription'],
        'sourceSystem': value['sourceSystem'],
        'premiumTotal': value['premiumTotal'],
        'premiumEmployer': value['premiumEmployer'],
        'debtClient': value['debtClient'],
        'debtEmployer': value['debtEmployer'],
        'overpaymentClient': value['overpaymentClient'],
        'overpaymentEmployer': value['overpaymentEmployer'],
        'surrenderValue': value['surrenderValue'],
        'sumPremiumClient': value['sumPremiumClient'],
        'sumPremiumEmployer': value['sumPremiumEmployer'],
    };
}

