import { InputBase, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { useField, useFormikContext } from "formik";
import { IconButton } from "gatsby-theme-material-ui";
import { ChangeEvent, useEffect } from "react";
import NumericFormat from "react-number-format";

import MinusButton from "../Icons/MinusButton";
import PlusButton from "../Icons/PlusButton";

const raiseOfmonthlyContribution = 500;

const AmountInput = ({
	monthlyContribution,
}: {
	monthlyContribution: number;
}) => {
	const [field] = useField("amount");
	const isDesktop = useIsDesktop();
	const { setFieldValue } = useFormikContext();

	//Adding default max value for the maximal pension tax saving to the input field
	useEffect(() => {
		void setFieldValue(
			"amount",
			monthlyContribution + raiseOfmonthlyContribution
		);
	}, [monthlyContribution, setFieldValue]);

	return (
		<Stack sx={{ flexShrink: isDesktop ? 1 : 0 }} direction="row">
			<IconButton
				onClick={() => {
					if (field.value - 50 > 0)
						void setFieldValue("amount", field.value - 50);
					else void setFieldValue("amount", 0);
				}}
				placeholder={undefined}
				onPointerEnterCapture={undefined}
				onPointerLeaveCapture={undefined}
			>
				<MinusButton sx={{ width: "2.4rem", height: "2.4rem" }} />
			</IconButton>
			<NumericFormat
				suffix={" Kč"}
				customInput={InputBase}
				sx={{
					width: 105,
					height: 42,
					p: 3,
					border: "1px solid #ccc",
					borderRadius: 2,
					background: "#fff",
					fontWeight: 600,
					fontSize: 20,
					"& .MuiInputBase-input": {
						p: 0,
						textAlign: "right",
					},
				}}
				{...field}
				onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
					const amount = parseInt(
						e.target.value.substring(0, e.target.value.length - 3)
					);
					void setFieldValue(
						"amount",
						isNaN(amount) || amount < 0 ? 0 : amount
					);
				}}
			/>
			<IconButton
				onClick={() => {
					if (field.value + 50 < 5700)
						void setFieldValue("amount", field.value + 50);
					else void setFieldValue("amount", 5700);
				}}
				placeholder={undefined}
				onPointerEnterCapture={undefined}
				onPointerLeaveCapture={undefined}
			>
				<PlusButton sx={{ width: "2.4rem", height: "2.4rem" }} />
			</IconButton>
		</Stack>
	);
};

export default AmountInput;
