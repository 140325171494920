import React, { CSSProperties, PropsWithChildren } from "react";

export const BgImage: React.FC<
	PropsWithChildren<{
		image?: {
			readonly base64: string | null;
			readonly width: number;
			readonly height: number;
			readonly src: string;
			readonly srcSet: string;
			readonly srcWebp: string | null;
			readonly srcSetWebp: string | null;
		} | null;
		style?: CSSProperties;
	}>
> = ({ children, style, image }) => {
	return (
		<div
			style={{
				backgroundPosition: "right top",
				backgroundRepeat: "no-repeat",
				backgroundSize: "350px 365px",
				width: "100%",
				height: "100%",
				backgroundImage: image && image.src ? 'url("' + image.src + '")' : "",
				...style,
			}}
		>
			{children}
		</div>
	);
};
