import { Stack } from "@mui/material";
import useIsDesktop, { useIsTablet } from "desktop/useIsDesktop";
import { useField } from "formik";

import cs from "../../translations/cs.json";
import {
	possibleTaxSavingFormulaAfterNovel,
	yearlyStateContributionFormula,
	yearlyStateContributionFormulaAfterNovel,
} from "../DPS/formulas";
import { BodyTextM, HeadingM, HeadingS } from "../Typography";
import AmountInput from "./AmountInput";
import PossibleTaxSaving from "./PossibleTaxSaving";

const Result = () => {
	const isDesktop = useIsDesktop();
	const isTablet = useIsTablet();

	const [field] = useField("amount");
	const monthlyStateContribution =
		yearlyStateContributionFormula(field.value) / 12;
	const monthlyStateContributionAfterNovel =
		yearlyStateContributionFormulaAfterNovel(field.value) / 12;
	const possibleTaxSaving = possibleTaxSavingFormulaAfterNovel(field.value);
	return (
		<>
			<Stack direction="row" sx={{ alignItems: "center" }}>
				<HeadingM sx={{ mr: isDesktop ? 5 : 0, mb: 0, color: "primary.main" }}>
					{cs.pensionCalculator.result.yourMonthlyContribution}:
				</HeadingM>
				<AmountInput />
			</Stack>
			<Stack direction={isTablet ? "row" : "column"} spacing={isTablet ? 0 : 4}>
				<Stack
					sx={{ width: isTablet ? "50%" : "100%", mr: isTablet ? "3%" : 0 }}
					spacing={2}
				>
					<HeadingS sx={{ mb: 0, color: "#6D6D6D" }} withoutScale>
						{cs.pensionCalculator.result.currentBenefits}
					</HeadingS>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<BodyTextM sx={{ color: "#6D6D6D" }}>
							{cs.pensionCalculator.result.stateContribution}
						</BodyTextM>
						<HeadingM mb={0} sx={{ color: "#6D6D6D" }}>
							{monthlyStateContribution}
							{"\u00A0"}Kč
						</HeadingM>
					</Stack>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<BodyTextM sx={{ color: "#6D6D6D" }}>
							{cs.pensionCalculator.result.possibleTaxSaving}
						</BodyTextM>
						<HeadingM mb={0} sx={{ color: "#6D6D6D" }}>
							{possibleTaxSaving}
							{"\u00A0"}Kč
						</HeadingM>
					</Stack>
				</Stack>
				<Stack sx={{ width: isTablet ? "50%" : "100%" }} spacing={2}>
					<HeadingS sx={{ mb: 0 }} withoutScale>
						{cs.pensionCalculator.result.your2024Benefits}
					</HeadingS>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<BodyTextM>
							{cs.pensionCalculator.result.stateContribution}
						</BodyTextM>
						<HeadingM
							mb={0}
							sx={{
								color:
									field.value < 1150
										? "#E64415"
										: field.value === 1150
										? "#404040"
										: "#538B4C",
							}}
						>
							{monthlyStateContributionAfterNovel}
							{"\u00A0"}Kč
						</HeadingM>
					</Stack>
					<PossibleTaxSaving currentTaxAmount={possibleTaxSaving} />
				</Stack>
			</Stack>
		</>
	);
};

export default Result;
