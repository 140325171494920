/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ActiveDirectoryAction } from './ActiveDirectoryAction';
import {
    ActiveDirectoryActionFromJSON,
    ActiveDirectoryActionFromJSONTyped,
    ActiveDirectoryActionToJSON,
} from './ActiveDirectoryAction';

/**
 * 
 * @export
 * @interface AuthorizeResponse
 */
export interface AuthorizeResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeResponse
     */
    version: string;
    /**
     * 
     * @type {ActiveDirectoryAction}
     * @memberof AuthorizeResponse
     */
    action: ActiveDirectoryAction;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeResponse
     */
    userMessage?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AuthorizeResponse
     */
    status?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AuthorizeResponse
     */
    statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeResponse
     */
    pID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeResponse
     */
    mPK?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeResponse
     */
    bANKERID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeResponse
     */
    bIRTHNUMBER?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeResponse
     */
    pHONE?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeResponse
     */
    eMAIL?: string | null;
}

/**
 * Check if a given object implements the AuthorizeResponse interface.
 */
export function instanceOfAuthorizeResponse(value: object): value is AuthorizeResponse {
    if (!('version' in value) || value['version'] === undefined) return false;
    if (!('action' in value) || value['action'] === undefined) return false;
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    return true;
}

export function AuthorizeResponseFromJSON(json: any): AuthorizeResponse {
    return AuthorizeResponseFromJSONTyped(json, false);
}

export function AuthorizeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'version': json['version'],
        'action': ActiveDirectoryActionFromJSON(json['action']),
        'userMessage': json['userMessage'] == null ? undefined : json['userMessage'],
        'status': json['status'] == null ? undefined : json['status'],
        'statusCode': json['statusCode'],
        'pID': json['PID'] == null ? undefined : json['PID'],
        'mPK': json['MPK'] == null ? undefined : json['MPK'],
        'bANKERID': json['BANKER_ID'] == null ? undefined : json['BANKER_ID'],
        'bIRTHNUMBER': json['BIRTHNUMBER'] == null ? undefined : json['BIRTHNUMBER'],
        'pHONE': json['PHONE'] == null ? undefined : json['PHONE'],
        'eMAIL': json['EMAIL'] == null ? undefined : json['EMAIL'],
    };
}

export function AuthorizeResponseToJSON(value?: AuthorizeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'version': value['version'],
        'action': ActiveDirectoryActionToJSON(value['action']),
        'userMessage': value['userMessage'],
        'status': value['status'],
        'statusCode': value['statusCode'],
        'PID': value['pID'],
        'MPK': value['mPK'],
        'BANKER_ID': value['bANKERID'],
        'BIRTHNUMBER': value['bIRTHNUMBER'],
        'PHONE': value['pHONE'],
        'EMAIL': value['eMAIL'],
    };
}

