import {
	useMutation,
	useQuery,
	useQueryClient,
	UseQueryOptions,
} from "@tanstack/react-query";

import { SetConsentsPutRequest } from "../../apis";
import { GetConsentResponse } from "../../models";
import { usePrimaryAPIClient } from "../context/PrimaryAPIClient";
import { useQueryKeyFactory } from "../context/QueryKeyProvider";

interface IUseConsents<TResult = GetConsentResponse[]> {
	options?: UseQueryOptions<GetConsentResponse[], unknown, TResult>;
}

export const useConsents = <T extends unknown = GetConsentResponse[]>({
	options,
}: IUseConsents<T> = {}) => {
	const { consentApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery<GetConsentResponse[], unknown, T>({
		queryKey: createQueryKey({ key: ["getConsents"] }),
		queryFn: async () => await consentApi.getConsentsGet(),
		...options,
	});
};

export const usePutConsents = () => {
	const { consentApi } = usePrimaryAPIClient();
	const client = useQueryClient();

	return useMutation({
		mutationKey: ["putConsents"],
		mutationFn: async (consent: SetConsentsPutRequest) => {
			return await consentApi.setConsentsPut(consent);
		},
		onSuccess: (_, variables) => {
			const newConsent: GetConsentResponse = {
				type: variables.setConsentRequest.consentType,
				consent: variables.setConsentRequest.consent,
			};

			client.setQueryData<GetConsentResponse[] | undefined>(
				["getConsents"],
				(oldData) => {
					if (oldData === undefined) {
						return;
					}

					return oldData.map((consent) =>
						consent.type === newConsent.type ? newConsent : consent
					);
				}
			);
		},
	});
};
