import Stack, { StackProps } from "@mui/material/Stack";
import { graphql, useStaticQuery } from "gatsby";

import { BgImage } from "../Image/BgImage";

const robotImage = () => {
	return useStaticQuery<Queries.ErrorContentRobotImageQuery>(
		graphql`
			query ErrorContentRobotImage {
				file(name: { eq: "robot" }) {
					childImageSharp {
						fixed(quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	);
};

const ErrorContent = ({ children, sx, ...rest }: StackProps): JSX.Element => {
	return (
		<Stack sx={{ flexGrow: 1, justifyContent: "center", my: 4 }}>
			<Stack
				spacing={7}
				sx={{
					maxWidth: { md: 450, xxs: "unset" },
					minHeight: 450,
					mb: { md: 0, xxs: 8 },
					mx: { md: "auto !important", xxs: 4 },
					...sx,
				}}
				{...rest}
			>
				<BgImage
					image={robotImage().file?.childImageSharp?.fixed}
					style={{
						backgroundSize: "contain",
						flexGrow: 1,
						display: "flex",
					}}
				>
					<Stack sx={{ flexGrow: 1 }} justifyContent="space-around">
						<Stack gap={5}>{children}</Stack>
					</Stack>
				</BgImage>
			</Stack>
		</Stack>
	);
};

export default ErrorContent;
