import cs from "../../translations/cs.json";
import Attention from "../AttentionBlock";
import HandMoneyIcon from "../Icons/HandMoney";

const Attentions = () => {
	return (
		<>
			<Attention
				severity="success"
				sx={{ backgroundColor: "#E9F2E7" }}
				icon={
					<HandMoneyIcon
						sx={{
							width: "2.4rem",
							height: "2.4rem",
							color: "#538B4C",
						}}
					/>
				}
			>
				{cs.pensionCalculator.attentions.defaultAttention}
			</Attention>
		</>
	);
};

export default Attentions;
