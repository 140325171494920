import { useMsal } from "@azure/msal-react";
import { createContext, ReactNode, useContext } from "react";

import {
	AuthenticateApi,
	BannersApi,
	CallCenterApi,
	ChangeRequestsApi,
	ConsentApi,
	ContactsApi,
	ContractsApi,
	ContractsDpsApi,
	ContractsLifeApi,
	ContractsPpApi,
	DocumentsApi,
	InvestorProfileApi,
	PostLoginApi,
	ProfileApi,
	RegistrationApi,
} from "../../apis";
import { DevelopmentDataConfig, ErrorResponse } from "../../models";
import {
	Configuration,
	ConfigurationParameters,
	HTTPHeaders,
} from "../../runtime";
import { EnvironmentHelper } from "../functions/env";
import { useDataConfig } from "./DataConfigContext";

interface IContext {
	callCenterApi: CallCenterApi;
	profileApi: ProfileApi;
	changeRequestsApi: ChangeRequestsApi;
	consentApi: ConsentApi;
	contactsApi: ContactsApi;
	contractsApi: ContractsApi;
	documentsApi: DocumentsApi;
	investorProfileApi: InvestorProfileApi;
	configurationParameters: ConfigurationParameters;
	contractsDpsApi: ContractsDpsApi;
	contractsLifeApi: ContractsLifeApi;
	contractsPpApi: ContractsPpApi;
	authenticateApi: AuthenticateApi;
	postLoginApi: PostLoginApi;
	registrationApi: RegistrationApi;
	bannersApi: BannersApi;
}

export const PrimaryAPIClientContext = createContext<IContext>({} as IContext);

export function usePrimaryAPIClient(): IContext {
	return useContext(PrimaryAPIClientContext);
}

interface Props {
	children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export class FetchApiError<T extends {} = ErrorResponse> extends Error {
	constructor(public errorResponse: T, message?: string) {
		super(message);
	}
}

export const parseError = async (
	response: Response
): Promise<ErrorResponse> => {
	try {
		return (await response.json()) as ErrorResponse;
	} catch {
		return {
			code: response.status,
			message: "Uknown error response without body.",
		};
	}
};

const getHeaders = (
	config: DevelopmentDataConfig | undefined
): HTTPHeaders | undefined => {
	if (!config) return undefined;
	return {
		"X-DEV-DATA-CONFIG": JSON.stringify(config),
	};
};

export const assertSuccessStatusCode = async (response: Response) => {
	if (!response.ok) {
		throw new FetchApiError(await parseError(response));
	}
};

export function PrimaryAPIClientProvider({ children }: Props): JSX.Element {
	const { config } = useDataConfig();
	const { instance } = useMsal();

	const configurationParameters: ConfigurationParameters = {
		async accessToken() {
			// TODO odchytit kdyz se nepovede acquirenout https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md#acquiring-an-access-token
			// TODO az se objevi druhe volani, udelame abstraktni metodu
			const tokenResponse =
				EnvironmentHelper.isLocal() && config.overrideToken
					? config.overrideToken
					: (
							await instance.acquireTokenSilent({
								scopes: ["openid", process.env.GATSBY_B2C_API_SCOPE || ""],
							})
					  ).accessToken;
			return `Bearer ${tokenResponse}`;
		},
		headers: getHeaders(!EnvironmentHelper.isProd() ? config : undefined),
		basePath: process.env.GATSBY_API_BASE_URL,
		middleware: [
			{
				post: async (context) => {
					await assertSuccessStatusCode(context.response);
				},
			},
		],
	};

	const configuration = new Configuration(configurationParameters);

	return (
		<PrimaryAPIClientContext.Provider
			value={{
				callCenterApi: new CallCenterApi(configuration),
				profileApi: new ProfileApi(configuration),
				changeRequestsApi: new ChangeRequestsApi(configuration),
				consentApi: new ConsentApi(configuration),
				contactsApi: new ContactsApi(configuration),
				contractsApi: new ContractsApi(configuration),
				documentsApi: new DocumentsApi(configuration),
				investorProfileApi: new InvestorProfileApi(configuration),
				contractsDpsApi: new ContractsDpsApi(configuration),
				contractsLifeApi: new ContractsLifeApi(configuration),
				contractsPpApi: new ContractsPpApi(configuration),
				authenticateApi: new AuthenticateApi(configuration),
				postLoginApi: new PostLoginApi(configuration),
				registrationApi: new RegistrationApi(configuration),
				bannersApi: new BannersApi(configuration),
				configurationParameters,
			}}
		>
			{children}
		</PrimaryAPIClientContext.Provider>
	);
}
