import { Stack } from "@mui/material";

import { LoggedOutBar } from "../../components/AppBar";
import Attention from "../../components/AttentionBlock";
import BankIcons from "../../components/BankIcons";
import DarkBox from "../../components/InfoBox/DarkBox";
import { BackLink } from "../../components/Link/InternalLink";
import LoginButton from "../../components/LoginButton";
import { loginErrorToText } from "../../components/LoginButton/useLogin.helpers";
import { useLoginError } from "../../components/LoginButton/useLoginError";
import Overview from "../../components/Overview";
import GuidepostSimplified from "../../components/Rozcestnik/GuidepostSimplified";
import { SEO } from "../../components/SEO";
import {
	BodyTextM,
	BodyTextS,
	HeadingL,
	HeadingS,
} from "../../components/Typography";

export const AppBar = (): JSX.Element => <LoggedOutBar />;

function LoginPage(): JSX.Element {
	const { authority, loginError } = useLoginError();

	if (process.env.GATSBY_FEATURE_IS_SIMPLE_GUIDEPOST_ENABLED === "true") {
		return <GuidepostSimplified />;
	}

	return (
		<>
			<Overview
				alt="Přihlášení"
				sx={{
					overflow: { md: "visible", xxs: "hidden" },
					mb: { md: 0, xxs: -4 },
					mx: { md: 0, xxs: -4 },
					p: { md: 0 },
					pt: { md: "72px" },
					".MuiCardContent-root": {
						boxShadow: (theme) => theme.customShadows.close,
					},
					".MuiCardMedia-root": {
						display: { md: "none" },
						pb: (theme) => theme.spacing(3),
					},
				}}
			>
				<Stack sx={{ px: 3 }}>
					<BackLink mb={4} to="/prihlaseni-nebo-registrace" />
					<HeadingL>Přihlášení</HeadingL>
				</Stack>
			</Overview>
			<Stack
				spacing={5}
				sx={{
					p: { md: 5 },
					boxShadow: (theme) => ({
						md: theme.customShadows.close,
					}),
					backgroundColor: "white.main",
				}}
			>
				{authority === undefined && loginError && (
					<Attention
						severity="error"
						title={loginErrorToText[loginError.errorCode] ?? undefined}
					>
						{loginError.errorMessage}
					</Attention>
				)}
				<BodyTextM>Vyberte si jednu z možností</BodyTextM>
				<DarkBox>
					<HeadingS withoutScale>BankID</HeadingS>
					<BodyTextS>Doporučujeme použít tuto možnost.</BodyTextS>
					<LoginButton journey="bank-id" />
					<BankIcons />
					{authority === "B2C_1A_BANKIDPOLICY" && loginError && (
						<Attention
							severity="error"
							title={loginErrorToText[loginError.errorCode] ?? undefined}
						>
							{loginError.errorMessage}
						</Attention>
					)}
				</DarkBox>
				<DarkBox>
					<HeadingS withoutScale>E-mail a heslo</HeadingS>
					<BodyTextS>Přihlášení přes e-mail</BodyTextS>
					<LoginButton
						journey="local-login"
						sx={{ alignSelf: { md: "flex-start" } }}
						data-test="login-button"
					/>
					{authority &&
						["B2C_1A_LOGINPOLICY", "B2C_1_PrototypeLogin"].includes(
							authority
						) &&
						loginError && (
							<Attention
								severity="error"
								title={loginErrorToText[loginError.errorCode] ?? undefined}
							>
								{loginError.errorMessage}
							</Attention>
						)}
				</DarkBox>
			</Stack>
		</>
	);
}

export default LoginPage;

export const Head = (): JSX.Element => <SEO title="Přihlášení" />;
