import { Skeleton, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";

import { GetLifeDetailV2Response, ProductType } from "../../../../models";
import { contractLink, NumberToCZK } from "../../../functions";
import { useLifeDetail } from "../../../queryHooks";
import cs from "../../../translations/cs.json";
import CaretLeft from "../../Icons/CaretLeft";
import CaretRight from "../../Icons/CaretRight";
import { BodyTextS, HeadingM } from "../../Typography";
import { findFufiFirstPerson } from "../../ZivotniPojisteni/Heading/Subtitle";
import { DetailLink } from "../ContractCardDesktopMenu";
import {
	CarretWrapper,
	contractTypeToDescription,
	contractTypeToTitle,
} from ".";

export type ContractHeaderProps = {
	title?: string;
	subtitle?: string;
	personDescription?: string | null;
	link?: string;
	ammount: {
		description: string;
		display?: string | JSX.Element;
	};
};

export const ContractCardHeaderView = ({
	ammount,
	link,
	personDescription,
	subtitle,
	title,
}: ContractHeaderProps) => {
	const isDesktop = useIsDesktop();

	const personDescriptionElement = (() => {
		if (personDescription === null) return null;
		return (
			<BodyTextS sx={{ mb: 0, whiteSpace: "pre-wrap" }}>
				{personDescription ?? <Skeleton />}
			</BodyTextS>
		);
	})();

	return (
		<Stack
			data-test="contract"
			sx={{
				flexGrow: 1,
				height: "100%",
			}}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				gap={4}
			>
				<HeadingM sx={{ flexGrow: 1, mb: 0, pr: 4 }}>
					{title ?? <Skeleton />}
				</HeadingM>
			</Stack>
			<BodyTextS light sx={{ mb: 4 }}>
				{subtitle ? subtitle : <Skeleton />}
			</BodyTextS>
			{personDescriptionElement}
			<BodyTextS sx={{ mb: 0 }} light>
				{ammount.description}
			</BodyTextS>
			<BodyTextS sx={{ mb: 0 }}>{ammount.display ?? <Skeleton />}</BodyTextS>
			{!isDesktop && <DetailLink link={link} />}
		</Stack>
	);
};

interface IContractHeaderWithPensionData {
	contractId: string;
}

export const ContractHeaderWithFufiData = ({
	contractId,
}: IContractHeaderWithPensionData) => {
	const isDesktop = useIsDesktop();
	const { data, isSuccess } = useLifeDetail<GetLifeDetailV2Response>({
		id: contractId,
		contractType: ProductType.Clf,
	});

	const title = isDesktop
		? data?.productDescription?.split("/")[0].trim()
		: contractTypeToTitle[ProductType.Clf];

	const subtitle = (() => {
		if (!isSuccess) return undefined;
		if (isDesktop) return `Smlouva číslo ${data.proposalNumber}`;
		if (data.productDescription)
			return `${data.productDescription} ${data.proposalNumber}`;
		return `Smlouva číslo ${data.proposalNumber}`;
	})();

	const personDescription = (() => {
		if (!isSuccess) return undefined;
		const firstPerson = findFufiFirstPerson(data?.insuredPersons);
		if (!firstPerson) return null;
		const strings = [
			cs.life.global.owner,
			":\n" + firstPerson.firstName,
			firstPerson.lastName,
		].filter((string) => !!string);
		if (strings.length === 0) return null;
		return strings.join(" ");
	})();

	const ammountDisplay = (
		<>{isSuccess ? NumberToCZK(data.premiumClient) : <Skeleton width={30} />}</>
	);

	return (
		<ContractCardHeaderView
			title={title ?? undefined}
			subtitle={subtitle}
			personDescription={personDescription}
			ammount={{
				description: contractTypeToDescription[ProductType.Clf],
				display: ammountDisplay,
			}}
			link={contractLink(contractId, ProductType.Clf)}
		/>
	);
};

const carretStyles = {
	width: "0.8rem",
	height: "1.3rem",
	color: "primary.main",
};

interface IContractCardCarrets {
	isFirst: boolean;
	isLast: boolean;
	onShift: (modifier: number) => void;
	children: JSX.Element;
}

export const ContractHeaderMobileCarrets = ({
	isLast,
	isFirst,
	onShift,
	children,
}: IContractCardCarrets) => {
	return (
		<Stack
			direction={"row"}
			gap={1}
			sx={{ flexDirection: "row", alignItems: "center", width: "100%", py: 0 }}
		>
			<CarretWrapper onClick={() => onShift(-1)}>
				<CaretLeft
					sx={{ ...carretStyles, visibility: isFirst ? "hidden" : "initial" }}
				/>
			</CarretWrapper>
			{children}
			<CarretWrapper onClick={() => onShift(1)}>
				<CaretRight
					sx={{ ...carretStyles, visibility: isLast ? "hidden" : "initial" }}
				/>
			</CarretWrapper>
		</Stack>
	);
};
