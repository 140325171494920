import { LoggedOutBar } from "../../components/AppBar";
import Guidepost from "../../components/Rozcestnik/Guidepost";
import GuidepostSimplified from "../../components/Rozcestnik/GuidepostSimplified";
import { SEO } from "../../components/SEO";

export const AppBar = (): JSX.Element => <LoggedOutBar />;

export const Head = (): JSX.Element => (
	<SEO
		description="Vyberte si svůj produkt, na který se chcete podívat."
		title="Rozcestník"
	/>
);

function RozcestnikPage(): JSX.Element {
	return (
		<>
			{process.env.GATSBY_FEATURE_IS_SIMPLE_GUIDEPOST_ENABLED === "true" ? (
				<GuidepostSimplified />
			) : (
				<Guidepost />
			)}
		</>
	);
}

export default RozcestnikPage;
