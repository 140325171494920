import { Skeleton } from "@mui/material";
import { useMemo } from "react";

import { PensionPhaseStatus } from "../../../models";
import { contractLink } from "../../functions";
import { useContracts, usePensionDetail } from "../../queryHooks";
import { PensionContractType } from "../../types/contracts";
import PensionNovelBanner from ".";

const maxPensionContributionForTheBiggiestTaxSaving = 5700;
const PensionNovelBannerWrapper = (): JSX.Element => {
	const { data, isSuccess } = useContracts();
	const contract = useMemo(() => {
		return data?.filter(
			(x) =>
				x.pensionPhaseStatus === PensionPhaseStatus.Saving &&
				x.isChildrenContract === false
		)[0];
	}, [data]);
	const contractDetail = usePensionDetail({
		id: contract?.id,
		type: contract?.type,
		options: {
			enabled: data !== undefined && data.length > 0,
		},
	});
	const isMaxPensionContribution =
		(contractDetail?.data?.contract?.monthlyContributionClient ?? 0) >=
		maxPensionContributionForTheBiggiestTaxSaving;

	if (isMaxPensionContribution || contract === undefined) return <></>;

	if (!isSuccess && !contractDetail.isSuccess)
		return <Skeleton variant="rounded" height={"128px"} width="100%" />;
	return (
		<PensionNovelBanner
			id={contract.id}
			type={contract.type as PensionContractType}
			url={contractLink(contract.id, contract.type, "smlouva")}
		/>
	);
};

export default PensionNovelBannerWrapper;
