import { Stack } from "@mui/material";
import { Formik } from "formik";

import CallToAction from "../../components/PensionCalculator/CallToAction";
import Result from "../../components/PensionCalculator/Result";
import PensionSlider from "../../components/PensionCalculator/Slider";
import { SEO } from "../../components/SEO";
import { BodyTextM, HeadingXL } from "../../components/Typography";
import cs from "../../translations/cs.json";
import Attentions from "./Attentions";
import PortalResult from "./PortalResult";

function PensionCalculator({
	monthlyContribution = 1700,
	portalVersion,
	changeContributionUrl,
}: {
	monthlyContribution?: number;
	portalVersion?: boolean;
	changeContributionUrl?: string;
}): JSX.Element {
	return (
		<>
			<Formik
				initialValues={{
					amount: monthlyContribution,
				}}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting;
				}}
			>
				{({ values }) => {
					return (
						<>
							<Stack sx={{ px: 6 }}>
								<HeadingXL sx={{ mb: 4 }}>
									{cs.pensionCalculator.intro.heading}
								</HeadingXL>
								<Stack sx={{ mb: 5 }}>
									<BodyTextM>
										{cs.pensionCalculator.intro.description1}{" "}
										<BodyTextM strong>
											{cs.pensionCalculator.intro.description2}
										</BodyTextM>
									</BodyTextM>
								</Stack>
							</Stack>
							<PensionSlider />
							<Stack
								sx={{
									mt: 6,
									py: 5,
									px: 6,
									borderTop: "1px solid #ddd",
									borderBottom: "1px solid #ddd",
									background: "#F7F7F7",
								}}
								spacing={4}
							>
								{portalVersion ? (
									<PortalResult monthlyContribution={monthlyContribution} />
								) : (
									<Result />
								)}
								<Attentions />
							</Stack>
							<CallToAction
								portalVersion={portalVersion}
								changeContributionUrl={changeContributionUrl}
								newContribution={values.amount}
							/>
						</>
					);
				}}
			</Formik>
		</>
	);
}

export default PensionCalculator;

export const Head = (): JSX.Element => <SEO title="Penzijní kalkulačka" />;
