import { useMsal } from "@azure/msal-react";
import { useState } from "react";

import { LoginButtonType } from ".";
import { loginRedirect } from "./useLogin.helpers";

interface useLoginProps {
	isDisabled: boolean;
	click: () => void;
}

export default (journey: LoginButtonType): useLoginProps => {
	const msal = useMsal();
	const [isDisabled, setIsDisabled] = useState(false);

	const click = async () => {
		setIsDisabled(true);

		try {
			await loginRedirect(msal, journey);
		} catch (error) {
			setIsDisabled(false);
		} finally {
			setIsDisabled(false);
		}
	};

	return {
		isDisabled,
		click,
	};
};
