import { IMsalContext } from "@azure/msal-react";

import { LoginButtonType } from ".";

export const loginErrorToText: Record<string, string> = {
	server_error: "Chyba serveru",
};

export const localLogin =
	process.env.GATSBY_LOGIN_FLOW === "SIMPLE" &&
	process.env.GATSBY_B2C_SIGNUP_SIGNIN_POLICY_SIMPLE
		? process.env.GATSBY_B2C_SIGNUP_SIGNIN_POLICY_SIMPLE
		: process.env.GATSBY_B2C_SIGNUP_SIGNIN_POLICY;

export const journeyToPolicyName = {
	"bank-id": "B2C_1A_BANKIDPOLICY",
	"local-login": localLogin,
	"local-registration": "B2C_1A_REGISTRATIONPOLICY",
};

export const loginRedirect = async (
	msal: IMsalContext,
	journey: LoginButtonType,
	prefilledUsername?: string
) => {
	const domain = process.env.GATSBY_B2C_DOMAIN as string;
	const tenantId = process.env.GATSBY_B2C_TENANT_ID as string;
	const policy = journeyToPolicyName[journey];
	const authority = `https://${domain}/${tenantId}/${policy}`;
	localStorage.setItem("lastUsedAuthority", authority);

	await msal.instance.loginRedirect({
		scopes: ["openid", process.env.GATSBY_B2C_API_SCOPE || ""],
		authority,
		loginHint:
			["local-login", "local-registration"].includes(journey) &&
			prefilledUsername &&
			prefilledUsername.length > 0
				? prefilledUsername
				: undefined,
	});
};
