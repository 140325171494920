import { Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";

import cs from "../../translations/cs.json";
import { BodyTextS } from "../Typography";

const SliderDescription = () => {
	const isDesktop = useIsDesktop();

	return (
		<Stack
			sx={{
				mb: isDesktop ? 2 : 4,
				p: isDesktop ? 0 : 4,
				backgroundColor: isDesktop ? "#fff" : "#f7f7f7",
			}}
			direction={isDesktop ? "row" : "column"}
			justifyContent="space-between"
			spacing={isDesktop ? 0 : 2}
		>
			<Stack
				direction={isDesktop ? "row" : "column"}
				spacing={isDesktop ? 0 : 2}
			>
				<Stack
					sx={{ maxWidth: isDesktop ? 130 : "auto" }}
					direction={isDesktop ? "column" : "row"}
					justifyContent={isDesktop ? "flex-start" : "space-between"}
				>
					<BodyTextS sx={{ mb: 0 }} strong={isDesktop ? true : false}>
						Minimum pro získání {isDesktop && "státního"} příspěvku
					</BodyTextS>
					{!isDesktop && (
						<BodyTextS strong mb={0} alignSelf={"center"}>
							500&nbsp;Kč
						</BodyTextS>
					)}
					{isDesktop && (
						<Stack sx={{ ml: "20px" }}>
							<svg
								width="63"
								height="13"
								viewBox="0 0 63 13"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M63 13L61.9944 7.31474L57.5736 11.0282L63 13ZM52.5 0.5L52.8829 0.178404L52.733 0H52.5V0.5ZM0 1H52.5V0H0V1ZM52.1171 0.821596L59.7228 9.87592L60.4885 9.23273L52.8829 0.178404L52.1171 0.821596Z"
									fill="#6D6D6D"
								/>
							</svg>
						</Stack>
					)}
				</Stack>
				<Stack
					sx={{ maxWidth: isDesktop ? 130 : "auto", ml: isDesktop ? 1 : 0 }}
					direction={isDesktop ? "column" : "row"}
					justifyContent={isDesktop ? "flex-start" : "space-between"}
				></Stack>
				<Stack
					sx={{ maxWidth: isDesktop ? 250 : "auto" }}
					direction={isDesktop ? "column" : "row"}
					justifyContent={isDesktop ? "flex-start" : "space-between"}
				>
					<BodyTextS
						sx={{ mb: 0, ml: isDesktop ? "150px" : 0 }}
						strong={isDesktop ? true : false}
					>
						{isDesktop
							? cs.pensionCalculator.sliderDescriptions.highestContribution
							: cs.pensionCalculator.sliderDescriptions
									.savingAmountForBiggestContribution}
					</BodyTextS>
					{!isDesktop && (
						<BodyTextS strong mb={0} alignSelf={"center"}>
							1&nbsp;700&nbsp;Kč
						</BodyTextS>
					)}
					{isDesktop && (
						<Stack sx={{ ml: "135px" }}>
							<svg
								width="63"
								height="13"
								viewBox="0 0 63 13"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M0 13L1.00556 7.31474L5.42636 11.0282L0 13ZM10.5 0.5L10.1171 0.178404L10.267 0H10.5V0.5ZM63 1H10.5V0H63V1ZM10.8829 0.821596L3.27722 9.87592L2.51151 9.23273L10.1171 0.178404L10.8829 0.821596Z"
									fill="#6D6D6D"
								/>
							</svg>
						</Stack>
					)}
				</Stack>
			</Stack>
			<Stack
				sx={{ maxWidth: isDesktop ? 140 : "auto" }}
				direction={isDesktop ? "column" : "row"}
				justifyContent={isDesktop ? "flex-start" : "space-between"}
			>
				<BodyTextS sx={{ mb: 0 }} strong={isDesktop ? true : false}>
					Získáte {isDesktop && "navíc"} nejvyšší daňové zvýhodnění
				</BodyTextS>
				{!isDesktop && (
					<BodyTextS strong mb={0} alignSelf={"center"}>
						5&nbsp;700&nbsp;Kč
					</BodyTextS>
				)}
				{isDesktop && (
					<Stack sx={{ alignItems: "flex-end", mr: "18px" }}>
						<svg
							width="63"
							height="13"
							viewBox="0 0 63 13"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M63 13L61.9944 7.31474L57.5736 11.0282L63 13ZM52.5 0.5L52.8829 0.178404L52.733 0H52.5V0.5ZM0 1H52.5V0H0V1ZM52.1171 0.821596L59.7228 9.87592L60.4885 9.23273L52.8829 0.178404L52.1171 0.821596Z"
								fill="#6D6D6D"
							/>
						</svg>
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

export default SliderDescription;
