/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SavePhoneNumberRequest
 */
export interface SavePhoneNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof SavePhoneNumberRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof SavePhoneNumberRequest
     */
    phoneNumber: string;
}

/**
 * Check if a given object implements the SavePhoneNumberRequest interface.
 */
export function instanceOfSavePhoneNumberRequest(value: object): value is SavePhoneNumberRequest {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    return true;
}

export function SavePhoneNumberRequestFromJSON(json: any): SavePhoneNumberRequest {
    return SavePhoneNumberRequestFromJSONTyped(json, false);
}

export function SavePhoneNumberRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SavePhoneNumberRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'phoneNumber': json['phoneNumber'],
    };
}

export function SavePhoneNumberRequestToJSON(value?: SavePhoneNumberRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'phoneNumber': value['phoneNumber'],
    };
}

