import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { object, string } from "yup";

import { ValidateLocalAccountResponse } from "../../../models/ValidateLocalAccountResponse";
import Form from "../../components/Form/Form";
import TextInput from "../../components/Form/Input/Text";
import FormSubmit from "../../components/Form/Submit";
import { InternalButtonLink } from "../../components/Link";
import {
	loginErrorToText,
	loginRedirect,
} from "../../components/LoginButton/useLogin.helpers";
import Overview from "../../components/Overview";
import { BodyTextM, HeadingL } from "../../components/Typography";
import {
	FetchApiError,
	usePrimaryAPIClient,
} from "../../context/PrimaryAPIClient";
import { richImageData } from "../../utils";
import Attention from "../AttentionBlock";
import LoginButton from "../LoginButton";
import { useLoginError } from "../LoginButton/useLoginError";

interface imageProps {
	intro_image: {
		publicURL: string;
	};
	aegon: {
		publicURL: string;
	};
	ing: {
		publicURL: string;
	};
}

const introImage = (): imageProps => {
	return useStaticQuery(
		graphql`
			query RozcestnikImages {
				intro_image: file(relativePath: { eq: "rozcestnik.png" }) {
					publicURL
					childImageSharp {
						gatsbyImageData
					}
				}
				aegon: file(relativePath: { eq: "aegon.jpg" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		`
	);
};

export const useValidateLocalAccount = () => {
	const { registrationApi } = usePrimaryAPIClient();

	return useMutation({
		mutationFn: (email: string) => {
			return registrationApi.validateLocalAccountPost({
				validateLocalAccountRequest: {
					email: email,
				},
			});
		},
	});
};

function GuidepostSimplified(): JSX.Element {
	const msal = useMsal();
	const { mutateAsync: validateLocalAccount, isError } =
		useValidateLocalAccount();
	const images = richImageData(introImage());
	const { authority, loginError } = useLoginError();
	const loading = msal.inProgress !== InteractionStatus.None;

	return (
		<>
			<HeadingL>Přihlaste se ke svému účtu z pohodlí domova</HeadingL>

			<Overview
				image={introImage().intro_image.publicURL}
				alt="Přihlášení"
				sx={{
					overflow: { md: "visible", xxs: "hidden" },
					mb: { md: 0, xxs: -4 },
					mx: { md: 0, xxs: -4 },
					p: { md: 0 },
					borderRadius: 1,
					".MuiCardContent-root": {
						boxShadow: (theme) => theme.customShadows.close,
						borderRadius: 1,
						backgroundColor: "white.main",
					},
					".MuiCardMedia-root": {
						display: { md: "none" },
						backgroundPositionY: "2px",
					},
				}}
			>
				<Box
					sx={{
						m: { md: 3 },
					}}
				>
					{authority === undefined && loginError && (
						<Attention
							severity="error"
							title={loginErrorToText[loginError.errorCode] ?? undefined}
						>
							{loginError.errorMessage}
						</Attention>
					)}

					<BodyTextM>
						Rychlá a pohodlná registrace nebo přihlášení přes Vaši banku.
						<strong> Doporučujeme použít tuto možnost.</strong>
					</BodyTextM>
					<LoginButton journey="bank-id" sx={{ width: "100%", mt: 4 }} />

					{authority === "B2C_1A_BANKIDPOLICY" && loginError && (
						<Attention
							severity="error"
							title={loginErrorToText[loginError.errorCode] ?? undefined}
						>
							{loginError.errorMessage}
						</Attention>
					)}
				</Box>
			</Overview>
			<Stack
				spacing={5}
				style={{ marginTop: 12 }}
				sx={{
					p: { md: 5 },
					boxShadow: (theme) => ({
						md: theme.customShadows.close,
					}),
					borderRadius: "4px",
					backgroundColor: "white.main",
				}}
			>
				<BodyTextM>
					Můžete se také přihlásit nebo registrovat pomocí Vašeho{" "}
					<strong>e-mailu.</strong>
				</BodyTextM>

				{isError ? (
					<Stack
						mt={5}
						direction="row"
						justifyContent="space-between"
						spacing={5}
					>
						<InternalButtonLink
							to="/prihlaseni/"
							variant="outlined"
							sx={{ width: "50%", py: "11px" }}
						>
							<BodyTextM>Přihlášení</BodyTextM>
						</InternalButtonLink>
						{process.env.GATSBY_FEATURE_IS_REGISTRATION_ENABLED === "true" && (
							<InternalButtonLink
								to="/registrace/"
								variant="outlined"
								sx={{ width: "50%", py: "11px" }}
							>
								<BodyTextM>Registrace</BodyTextM>
							</InternalButtonLink>
						)}
					</Stack>
				) : (
					<Formik
						initialValues={{ email: "" }}
						validationSchema={object().shape({
							email: string().email().required(),
						})}
						onSubmit={async ({ email }, helpers) => {
							try {
								const res = await validateLocalAccount(email);

								if (res.isAccountRegistered === true) {
									await loginRedirect(msal, "local-login", email);
								} else {
									await loginRedirect(msal, "local-registration", email);
								}
							} catch (error) {
								if (error instanceof FetchApiError) {
									const err =
										error as FetchApiError<ValidateLocalAccountResponse>;

									if (err.errorResponse.statusCode !== 200) {
										helpers.setErrors({
											email:
												err?.errorResponse.userMessage ??
												"Nepodařilo se ověřit e-mail.",
										});
										return;
									}
								}

								helpers.setErrors({
									email: "Nepodařilo se ověřit e-mail.",
								});
							}
						}}
					>
						<Form spacing={2} showSubmitButton={false}>
							<TextInput
								placeholder="Váš e-mail"
								label={""}
								name={`email`}
								inputProps={{
									inputMode: "email",
								}}
								sx={{
									pb: { md: 5 },
								}}
							/>
							<FormSubmit
								variant={"contained"}
								ButtonComponent={LoadingButton}
								text={{
									submitting: "...",
									label: "Registrovat / Přihlásit se",
								}}
							/>

							{authority &&
								["B2C_1A_LOGINPOLICY", "B2C_1_PrototypeLogin"].includes(
									authority
								) &&
								loginError && (
									<Attention
										severity="error"
										title={loginErrorToText[loginError.errorCode] ?? undefined}
									>
										{loginError.errorMessage}
									</Attention>
								)}
						</Form>
					</Formik>
				)}
			</Stack>
			<Stack
				style={{ marginTop: 12 }}
				spacing={5}
				sx={{
					p: { md: 5 },
					boxShadow: (theme) => ({
						md: theme.customShadows.close,
					}),
					borderRadius: "4px",
					backgroundColor: "white.main",
				}}
			>
				<BodyTextM>
					<Stack>
						<ul data-clear style={{ marginTop: 0 }}>
							<li>
								Smlouva byla původně sjednána u{" "}
								<GatsbyImage image={images.aegon} alt="AEGON" />
							</li>
						</ul>
					</Stack>
					<Stack direction="row" spacing={4}>
						<Stack>
							<ul data-clear style={{ marginTop: 0 }}>
								<li>NN Orange Risk</li>
								<li>NN Orange Bonus</li>
							</ul>
						</Stack>
						<Stack>
							<ul data-clear style={{ marginTop: 0 }}>
								<li>NN Orange Invest</li>
								<li>NN Orange Care</li>
							</ul>
						</Stack>
					</Stack>
				</BodyTextM>

				<InternalButtonLink
					to="https://mojenn20.nn.cz"
					sx={{ width: "100%" }}
					disabled={loading}
				>
					Pokračovat na přihlášení
				</InternalButtonLink>
			</Stack>
			<Card style={{ marginTop: 12 }}>
				<CardContent>
					<BodyTextM>
						Pokud se Vám přihlášení nepovede, zavolejte nám na číslo 244 090 800
						a naši operátoři Vám pomůžou. Jsme tu pro vás!
					</BodyTextM>
				</CardContent>
			</Card>
		</>
	);
}

export default GuidepostSimplified;
