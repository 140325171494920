import { LoadingButtonTypeMap } from "@mui/lab/LoadingButton/LoadingButton";
import { ExtendButton } from "@mui/material/Button/Button";
import { useFormikContext } from "formik";
import { FC } from "react";

import TriggerButton, { TriggerButtonProps } from "../Link/TriggerButton";

export interface FormSubmitProps {
	text: {
		submitting: string;
		label: string;
	};
	disabled?: boolean;
	className?: string;
	onClick?: () => void;
	ButtonComponent?: FC<TriggerButtonProps> | ExtendButton<LoadingButtonTypeMap>;
}

const FormSubmit: FC<FormSubmitProps> = ({
	text,
	disabled = false,
	className = "",
	onClick = () => null,
	ButtonComponent = TriggerButton,
	...rest
}) => {
	const { isSubmitting } = useFormikContext();

	return (
		<ButtonComponent
			disabled={isSubmitting || disabled}
			className={`form-submit ${className}`}
			type="submit"
			onClick={onClick}
			{...rest}
		>
			{isSubmitting ? text.submitting : text.label}
		</ButtonComponent>
	);
};

export default FormSubmit;
