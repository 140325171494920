import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
	AllowedChangesItem,
	GetAllowedChangesResponse,
	GetContractsResponse,
} from "../../models";
import {
	FetchApiError,
	usePrimaryAPIClient,
} from "../context/PrimaryAPIClient";
import { useQueryKeyFactory } from "../context/QueryKeyProvider";

interface IAllowedChanges {
	options?: UseQueryOptions<GetAllowedChangesResponse>;
}

export const useAllowedChanges = ({ options }: IAllowedChanges) => {
	const { contractsApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();
	return useQuery({
		queryKey: createQueryKey({ key: ["getAllowedChangesGet"] }),
		queryFn: () => contractsApi.getAllowedChangesGet(),
		...options,
	});
};

interface IAllowedChangesForContract {
	id: string;
	options?: UseQueryOptions<
		GetAllowedChangesResponse,
		FetchApiError | Error,
		AllowedChangesItem
	>;
}

export const useAllowedChangesForContract = ({
	id,
	options,
}: IAllowedChangesForContract) => {
	const { contractsApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery<
		GetAllowedChangesResponse,
		FetchApiError | Error,
		AllowedChangesItem
	>({
		queryKey: createQueryKey({ key: ["getAllowedChangesGet"] }),
		queryFn: () => contractsApi.getAllowedChangesGet(),
		...{
			select: (data) => {
				const changesItem = data.contracts.find(
					(contract) => contract.contractId === id
				);
				if (changesItem === undefined) {
					throw new Error(`Changes item not found for contract: ${id}`);
				}
				return changesItem;
			},
			...options,
		},
	});
};

interface IContracts {
	options?: UseQueryOptions<GetContractsResponse[]>;
}

export const useContracts = ({ options }: IContracts = {}) => {
	const { contractsApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();
	return useQuery<GetContractsResponse[]>({
		queryKey: createQueryKey({ key: ["getContracts"] }),
		queryFn: () => {
			return contractsApi.getContractsJWTGet();
		},
		...options,
	});
};

interface IContractByIdProps {
	id: string;
	options?: UseQueryOptions<
		GetContractsResponse[],
		unknown,
		GetContractsResponse
	>;
}

export const useContractById = ({ id, options }: IContractByIdProps) => {
	const { contractsApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery<GetContractsResponse[], unknown, GetContractsResponse>({
		// Do not include contract id, it will lead to unnecessary refetching.
		queryKey: createQueryKey({ key: ["getContracts"] }),
		queryFn: () => {
			return contractsApi.getContractsJWTGet();
		},
		...{
			select: (data) =>
				data.find((contract) => contract.id === id) as GetContractsResponse,
			...options,
		},
	});
};
