import { Stack } from "@mui/material";

import { GetDocumentsResponse } from "../../../models";
import Download from "../Download";
import NoDownload from "../Download/NoDownload";
import { BgImage } from "../Image/BgImage";
import { HeadingL, HeadingM } from "../Typography";
import FileSkeleton from "./FileSkeleton";

interface Props {
	data?: Array<GetDocumentsResponse>;
	isLoading?: boolean;
}

const DocumentManager = ({
	data = [],
	isLoading = false,
}: Props): JSX.Element => {
	const loadingInProgress = (
		<>
			<FileSkeleton />
			<FileSkeleton />
			<FileSkeleton />
		</>
	);

	let year;
	const contentLoaded =
		data.length > 0 ? (
			data.map((item, i) => {
				const dateCreated = new Date(item.dateCreated);
				if (year !== dateCreated.getFullYear()) {
					// 1st document from every year
					year = dateCreated.getFullYear();
					return (
						<>
							<HeadingM withoutScale sx={{ mb: 0, pt: i !== 0 ? 4 : 0 }}>
								{year}
							</HeadingM>
							<Download key={`${item.name}-${i}`} {...item} />
						</>
					);
				} else return <Download key={`${item.name}-${i}`} {...item} />;
			})
		) : (
			<NoDownload />
		);

	return (
		<BgImage
			image={null}
			style={{
				backgroundPosition: "right 90px",
				backgroundSize: "397px 306px",
			}}
		>
			<Stack
				sx={{
					width: { md: "50%" },
					minHeight: { md: "350px" },
				}}
			>
				<Stack direction="row" justifyContent="space-between">
					<HeadingL withoutScale>Přehled dokumentů</HeadingL>
				</Stack>
				<Stack spacing={4} alignItems="flex-start">
					{isLoading ? loadingInProgress : contentLoaded}
				</Stack>
			</Stack>
		</BgImage>
	);
};
export default DocumentManager;
