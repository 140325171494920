import { Stack } from "@mui/material";
import { useField } from "formik";

import cs from "../../translations/cs.json";
import { possibleTaxSavingFormulaAfterNovel } from "../DPS/formulas";
import { BodyTextM, HeadingM } from "../Typography";

const PossibleTaxSaving = ({
	currentTaxAmount,
}: {
	currentTaxAmount: number;
}) => {
	const [field] = useField("amount");
	const possibleTaxSavingAfterNovel = possibleTaxSavingFormulaAfterNovel(
		field.value
	);

	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center">
			<BodyTextM>{cs.pensionCalculator.result.possibleTaxSaving}</BodyTextM>
			<HeadingM
				mb={0}
				sx={{
					color:
						currentTaxAmount > possibleTaxSavingAfterNovel
							? "#E64415"
							: currentTaxAmount === possibleTaxSavingAfterNovel
							? "#404040"
							: "#538B4C",
				}}
			>
				{possibleTaxSavingAfterNovel}
				{"\u00A0"}Kč
			</HeadingM>
		</Stack>
	);
};

export default PossibleTaxSaving;
