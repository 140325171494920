/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SavePhoneNumberRequest,
} from '../models/index';
import {
    SavePhoneNumberRequestFromJSON,
    SavePhoneNumberRequestToJSON,
} from '../models/index';

export interface SavePhoneNumberPostRequest {
    savePhoneNumberRequest?: SavePhoneNumberRequest;
}

/**
 * 
 */
export class PhoneNUmberApi extends runtime.BaseAPI {

    /**
     * Post phone verification number to ADB2C
     */
    async savePhoneNumberPostRaw(requestParameters: SavePhoneNumberPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/verificationMethods/phoneNumber`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SavePhoneNumberRequestToJSON(requestParameters['savePhoneNumberRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Post phone verification number to ADB2C
     */
    async savePhoneNumberPost(requestParameters: SavePhoneNumberPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.savePhoneNumberPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
